@import 'foundation-sites/scss/foundation';

@include foundation-global-styles;
@include foundation-flex-grid;

.lt-ie10 {
  @include foundation-grid;
}

@include foundation-flex-classes;
@include foundation-visibility-classes;

@include foundation-text-alignment;


.sidebar {

  .extension-container {
    height: auto;
    margin-bottom: 1.875rem;
  }

  .medium-3,
  .medium-4,
  .medium-6,
  .medium-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
