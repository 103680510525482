body {
  font-family: $font-family-serif;
}

h1,
h2,
h3 {
  font-weight: normal;
}

h1 {
  color: $color-primary;
  font-family: $font-family-sans-serif;
  font-size: 2rem;
  line-height: 2.438rem;
  margin: 0 0 2.5rem;

  @include breakpoint(small only) {
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin: 0 0 1rem;
  }
}

h2 {
  border-top: 2px solid $color-secondary;
  color: $color-secondary;
  font-family: $font-family-serif;
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin: 0 0 2.5rem;
  max-width: 85%;
  padding-top: 1.25rem;

  @include breakpoint(small only) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0 0 2rem;
    padding-top: .75rem;
  }
}

h3 {
  color: $color-primary;
  font-family: $font-family-sans-serif;
  font-size: 1.375rem;
  line-height: 1.75rem;
  margin: 2rem 0 1rem;

  a {
    display: inline-block;
    position: relative;

    &::after {
      background: $color-secondary url('../Images/cross.svg') center no-repeat;
      border-radius: 50%;
      content: '';
      height: 1.875rem;
      padding-left: .5rem;
      padding-top: .1875rem;
      position: absolute;
      right: -2.5rem;
      top: -.1875rem;
      transition: all 200ms linear;
      width: 1.875rem;

      @include breakpoint(small only) {
        padding-left: .438rem;
        padding-top: .28rem;
        right: -2.5rem;
        top: -.1875rem;
      }
    }

    &:hover::after {
      background-image: url('../Images/arrow.svg');
      content: '';
      transform: rotate(360deg);
      transform-origin: center;
    }
  }
}

a {
  text-decoration: none;
  transition: all 200ms linear;

  &:link,
  &:visited {
    color: $color-primary;
  }

  &:hover,
  &:active {
    color: $color-secondary;
  }
}


p {
  line-height: 1.375rem;
  margin: 0 0 1.5rem;
}

.highlight {
  color: $color-primary;
}

.btn {
  background: $color-primary;
  color: $white !important;
  display: inline-block;
  margin-top: .5rem;
  padding: .75rem 5rem;
}
