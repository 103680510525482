@import 'slick.js/slick/slick.scss';

// Bild-Slider
.slider {
  display: block;
  margin: -40px auto 50px;
  position: relative;
  z-index: 1;

  @include breakpoint(small only) {
    margin: 10px auto 35px;
  }
}

// IE Hack
.lt-ie10 {
  .slider {
    a {
      display: none;
    }

    .slider-item {
      display: none;

      div {
        display: inline-block;

        p {
          margin-top: 30%;
        }
      }
    }

    .slider-item:first-child {
      display: block;
      width: 100%;
    }
  }
}

.slider-item {
  overflow: hidden;
  position: relative;

  img {
    transition: all 250ms ease-out;
  }

  div {
    align-items: center;
    background-color: rgba($color-primary, .8);
    border-radius: 50%;
    bottom: -7%;
    display: flex;
    height: 28% * 2.171;
    justify-content: center;
    left: 12%;
    position: absolute;
    text-align: center;
    transition: all 200ms linear;
    width: 28%;

    @include breakpoint(medium only) {
      bottom: -12%;
      height: 40% * 2.171;
      left: 5%;
      width: 40%;
    }

    @include breakpoint(small only) {
      bottom: -12%;
      height: 50% * 2.171;
      left: .625rem;
      width: 50%;
    }

    p {
      transition: all 200ms linear;
    }

    &.primary {
      background-color: rgba($color-primary, .8);
    }

    &.secondary {
      background-color: rgba($color-secondary, .8);
    }

    &.tertiary {
      background-color: rgba($white, .8);

      p {
        color: #555;
      }
    }

    &.primary:hover,
    &.secondary:hover,
    &.tertiary:hover {
      transform: scale(1.05);
      transform-origin: center;

      p {
        transform: scale(.95);
      }
    }

    &.right {
      left: auto;
      right: 12%;

      @include breakpoint(medium only) {
        right: 5%;
      }

      @include breakpoint(small only) {
        right: .625rem;
      }
    }
  }

  p {
    color: $white;
    flex: 1;
    font-size: 2rem;
    line-height: 2.375rem;
    margin: 0;
    padding: 0 12%;

    @include breakpoint(medium only) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @include breakpoint(small only) {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }
}

.slider-item:hover {
  img {
    filter: grayscale(40%);
  }

  div {
    box-shadow: 0 0 5px 1px rgba(33, 33, 33, .125);
  }

  .primary {
    background-color: rgba($color-primary, .9);
  }

  .secondary {
    background-color: rgba($color-secondary, .9);
  }

  .tertiary {
    background-color: rgba($white, .9);
  }
}

.slick-dots,
.slick-prev,
.slick-next {
  display: none;
  visibility: hidden;
}




// Kontakt-Slider
.contactitem {
  background: transparent;
  min-height: 210px;

  .circle {
    border: 1px solid $color-secondary;
    border-radius: 50%;
    height: 154px;
    margin: 0 auto;
    padding-top: 6px;
    width: 154px;
  }

  img {
    border-radius: 50%;
    display: block;
    margin: 0 auto;
  }

  h4 {
    color: $color-primary;
    font-family: $font-family-sans-serif;
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 1.5rem;
  }
}

// IE Hack
.lt-ie10 {
  .contactitem {
    display: none;
  }

  .contactitem:first-child {
    display: block;
  }
}
