.cc {
  &_container {
    background-color: rgba(59, 56, 163, 0.85) !important;
  }

  &_more_info {
    color: $color-secondary !important;
  }

  &_btn {
    background-color: $color-secondary !important;
    border-radius: 0 !important;
    color: #fff !important;
    font-family: $font-family-sans-serif;
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 1.5rem;
    padding: 8px 15px 6px 13px !important;
  }

  &_message {
    font-family: $font-family-sans-serif;
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 1.5rem;
  }
}
