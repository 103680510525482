html,
body {
  font-size: $font-size-large;

  @include breakpoint(small only) {
    font-size: $font-size-small;
    overflow-x: hidden;
  }
}

body {
  background: #e7f1ff;

  @include breakpoint(small only) {
    margin: 10px auto 3px;
  }
}

.pagewrapper {
  background: $white;
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, .25);
  margin: 0 auto;
  max-width: 87.5rem;
}

.more-link {
  background-color: $color-secondary;
  display: block;
  height: 2px;
  margin: 30px 0 75px;
  position: relative;

  @include breakpoint(small only) {
    margin: 20px 0 5rem;
  }

  div {
    background: $color-secondary url('../Images/cross.svg') center no-repeat;
    border-radius: 50%;
    height: 28px;
    left: 50%;
    margin-left: -14px;
    position: absolute;
    top: -14px;
    transition: .25s ease-in-out;
    width: 28px;

    &:hover {
      background-image: url('../Images/arrow.svg');
      height: 34px;
      margin-left: -17px;
      top: -17px;
      transform: rotate(360deg);
      transform-origin: center;
      width: 34px;
    }
  }

  &:hover {
    transform: scale(.96);
  }
}

.more-link--bottom,
.divider {
  bottom: 1.25rem;
  margin: 0;
  position: absolute;
  width: 100%;

  @include breakpoint(small only) {
    bottom: 6rem;
  }
}

.divider {
  background-color: $color-secondary;
  border: 0;
  display: block;
  height: 2px;

  @include breakpoint(small only) {
    display: none;
  }
}


.heroimage {
  margin: -40px auto 50px;
  overflow: hidden;
  position: relative;

  @include breakpoint(small only) {
    margin: 10px auto 3px;
  }

  &::after {
    content: url('../Images/hero-bg.svg');
    height: 516px;
    left: 50%;
    margin-left: -258px;
    margin-top: -258px;
    position: absolute;
    top: 50%;
    width: 516px;
    z-index: 2;

    @include breakpoint(small only) {
      content: none;
    }
  }

  div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  h1 {
    color: $white;
    flex: 1;
    font-size: 2.5rem;
    font-weight: normal;
    line-height: 3.125rem;
    margin: 0;
    padding-top: 1.875rem;
    text-align: center;
    text-shadow: 1px 1px 2px $color-primary;
    text-transform: lowercase;
    z-index: 3;

    @include breakpoint(small only) {
      display: none;
    }
  }

  img {
    z-index: 1;
  }
}

.heroimage-mobile-headline {
  background: $color-secondary;
  color: $white;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.75rem;
  padding: .5rem .625rem .25rem;
  text-transform: lowercase;
}
