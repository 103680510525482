$font-size-large: 16px;
$font-size-small: 14px;

$global-flexbox: true;

$global-width: 70rem;

$color-primary: #3b38a3;
$color-secondary: #aaca7c;

$font-family-serif: 'Droid Serif', serif;
$font-family-sans-serif: 'FFSanukWeb', sans-serif;

$body-font-color: #464646;
$body-font-family: $font-family-sans-serif;
