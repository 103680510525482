.tx-gbevents-pi1 {
  [data-church] {
    min-height: 50px;
  }

  h4,
  h5,
  p {
    font-family: $font-family-serif;
    font-size: 1rem;
    line-height: 1.375rem;
  }

  h4 {
    font-weight: bold;
    margin: 0 0 .125rem;
  }

  h5 {
    color: $color-secondary;
    margin: .3px 0 .125rem;
  }

  p {
    margin: 0 0 2.063rem;
  }

  a {
    p {
      color: $body-font-color;
    }
  }
  .green {
    background: $color-secondary;
    background-image: url('../Images/gb-footer-one-white.svg');
    background-position: 180% 50%;
    background-repeat: no-repeat;
    background-size: 70%;
    margin-bottom: 1.5rem;
    padding: 1rem 1rem .01rem;

    a:hover {
      color: $white;
    }
  }
}
