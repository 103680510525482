.container-togglemobilenav {
  background: $color-primary;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .5);
  height: 54px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999999;
}

.togglemobilenav {
  cursor: pointer;
  left: .625rem;
  position: fixed;
  top: .625rem;
  transition: all 200ms ease-in;
  z-index: 99999999999;
}

.pushmenu {
  background: $color-primary;
  height: 100%;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  padding-right: 1rem;
  padding-top: 70px;
  position: fixed;
  top: 0;
  width: 310px;
  z-index: 1000;
}

.pushmenu-left {
  left: -310px;

  &.pushmenu-open {
    left: 0;
  }
}

.pushmenu,
.pushmenu-push {
  transition: all 200ms ease;
}

.pushmenu-push {
  left: 0;
  position: relative;
}

.pushmenu-push-toright {
  left: 310px;
}


// Mobile-Navigation
.mobilenavlist {
  list-style-type: none;
  margin: 0;
  padding-right: 0;

  a {
    color: $white;
    display: block;
    font-family: $font-family-sans-serif;
    padding: .75rem 0;
    text-transform: lowercase;

    &:hover,
    &:active {
      color: $color-secondary;
    }

    &.active {
      color: $color-secondary;
    }
  }
}

.mobilenavlist--level1 {
  padding-left: 1rem;

  a {
    border-bottom: 1px dotted #5951d2;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.4rem;
  }
}

.mobilenavlist--level2 {
  padding-left: 1rem;

  a {
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.125rem;
  }
}

.mobilenavlist--level3 {
  padding-left: 1.25rem;

  a {
    font-size: .95rem;
    font-weight: normal;
    line-height: 1.125rem;
  }
}

.hamburger-icon {
  cursor: pointer;
  height: 18px;
  left: 6px;
  position: absolute;
  top: 9px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 24px;

  span {
    background: #fff;
    display: block;
    height: 4px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transform-origin: left center;
    transition: .25s ease-in-out;
    width: 100%;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: 7px;
  }

  span:nth-child(3) {
    top: 14px;
  }

  &.open span:nth-child(1) {
    left: 4px;
    top: 0;
    transform: rotate(45deg);
  }

  &.open span:nth-child(2) {
    opacity: 0;
    width: 0%;
  }

  &.open span:nth-child(3) {
    left: 4px;
    top: 17px;
    transform: rotate(-45deg);
  }
}

.mobilenavlist{
  .mobilenavlist {
    display: none;
  }

  [type="checkbox"] {
    display: none;

    & + label {
      background: url('../Images/cross.svg') center no-repeat;
      cursor: pointer;
      display: inline;
      height: 15px;
      margin-top: -2.8rem;
      padding: 1.4rem;
      position: absolute;
      right: 0;
      transition: .25s ease-in-out;
      width: 15px;
    }

    &:checked + label {
      background: url('../Images/line.svg') center no-repeat;
    }

    &:checked ~ ul {
      display: inline-block;
      width: 100%;
    }
  }
}
