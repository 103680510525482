.ce-align-left {
  text-align: left;
}

.ce-align-center {
  text-align: center;
}

.ce-align-right {
  text-align: right;
}

.ce-table {
  margin-bottom: 2.5rem;
  width: 100%;

  td,
  th {
    vertical-align: top;
  }

  th {
    background-color: $color-secondary;
    border-right: 1px solid #fff;
    color: #fff;
    font-family: $font-family-sans-serif;
    padding: 14px 8px 12px 10px;
    text-align: left;

    &:last-child {
      border-right: 0;
    }
  }

  td {
    border-right: 1px solid rgba($color-secondary, .8);
    padding: 14px 8px 12px 10px;
    min-width: 140px;

    &:last-child {
      border-right: 0;
    }
  }

  tr:nth-child(2n) td {
    background-color: rgba($color-secondary, .2);
  }

  caption {
    color: $color-primary;
    font-family: $font-family-sans-serif;
    text-align: left;
    font-weight: 600;
  }
}

.ce-textpic,
.ce-nowrap .ce-bodytext,
.ce-gallery,
.ce-row {
  overflow: hidden;
}

.ce-uploads {
  margin: 0;
  padding: 0;

  li,
  div {
    overflow: hidden;
  }

  li {
    list-style: none outside none;
    margin: 1em 0;
  }

  img {
    float: left;
    padding-right: 1em;
    vertical-align: top;
  }

  span {
    display: block;
  }
}

.ce-left .ce-gallery,
.ce-column {
  float: left;
}

.ce-center {
  .ce-outer {
    float: right;
    position: relative;
    right: 50%;
  }

  .ce-inner {
    float: right;
    position: relative;
    right: -50%;
  }
}

.ce-right .ce-gallery {
  float: right;
}

.ce-gallery {
  width: 100%;

  &[data-ce-columns="1"][data-ce-images="1"] {
    max-width: 50%;

    @include breakpoint(small only) {
      width: 100%;
    }
  }

  figure {
    display: table;
    margin: 0;
  }

  figcaption {
    border-left: 4px solid $color-secondary;
    caption-side: bottom;
    color: $color-primary;
    display: table-caption;
    font-size: .875rem;
    margin: 8px 0 0 20px;
    padding-left: 5px;
  }

  img {
    display: block;
  }

  iframe {
    border-width: 0;
  }
}

.ce-border {
  img,
  iframe {
    border: 2px solid #000;
    padding: 0;
  }
}

.ce-above .ce-gallery {
  margin-bottom: 40px;
}

.ce-intext {
  &.ce-right .ce-gallery,
  &.ce-left .ce-gallery {
    margin-bottom: 10px;
  }

  &.ce-right .ce-gallery {
    margin-left: 10px;
  }

  &.ce-left {
    ol,
    ul {
      overflow: auto;
      padding-left: 40px;
    }

    .ce-gallery {
      margin-right: 10px;
    }
  }
}

.ce-below .ce-gallery {
  margin-bottom: 4rem;
  margin-top: 10px;
}

[data-ce-columns='1'] .ce-column {
  width: 100%;
}

[data-ce-columns='2'] .ce-column {
  width: 50%;
}

[data-ce-columns='3'] .ce-column {
  width: 33%;
}

.ce-column {
  padding-right: 8px;

  &:last-child {
    padding-right: 0;
  }
}

.ce-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ce-above .ce-bodytext {
  clear: both;
}


.ce-media {
  img {
    @include breakpoint(small only) {
      width: 100%;
    }
  }

  img:last-child {
    margin-bottom: 1.25rem;
  }
}

.ce-employee {
  margin-bottom: 2rem;

  .ce-row {
    display: flex;
  }

  .ce-column {
    max-width: 50%;

    &:first-child {
      width: 15rem;
    }
  }

  img {
    border-radius: 50%;
  }
}
