.sitemap--level1 {
  margin-top: 0;
  padding-left: 20px;

  li {
    font-weight: bold;
    padding: 3px 0;

    a {
      border-bottom: 1px dotted #ddd;
      display: block;
      padding: 5px 0;
    }

    a:hover {
      color: $color-secondary;
    }
  }
}

.sitemap--level2,
.sitemap--level3 {
  padding-left: 20px;

  li {
    font-weight: normal;
  }
}
