.fs-media-gallery {
  clear: both;

  // Styling der Alben-Übersicht
  .gallery--album {
    .item {
      margin-bottom: 6%;
      padding-right: 6%;
      width: 50%;

      &:nth-child(2n) {
        padding-right: 0;
      }

      &__image {
        height: 180px;
      }

      span {
        color: $body-font-color;
        font-size: .875rem;
        font-weight: normal;
        margin-top: 1.33em;
      }

      h3 {
        display: block;
        font-size: .875rem;
        margin: .33em 0;
      }
    }
  }

  // Styling der Bilder-Übersicht in Album
  .gallery--asset {
    .item {
      margin-bottom: 3%;
      padding-right: 3%;
      width: 33%;

      &:nth-child(3n) {
        padding-right: 0;
      }

      &__image {
        height: 200px;
      }
    }
  }

  // Styling der Bilder-Darstellung ohne Lightbox
  .gallery--asset--detail {
    figure {
      margin: 0;
    }

    figcaption {
      font-size: 1.25rem;
      font-weight: normal;
      margin-top: 1.33em;
    }
  }

  .item {
    float: left;
    overflow: hidden;

    &:hover {
      cursor: pointer;

      img {
        transform: scale(1.2);
      }
    }

    &__image {
      background: $color-secondary;
      background-image: url('../Images/gb-footer-one-white.svg');
      background-position: 180% 50%;
      background-repeat: no-repeat;
      background-size: 70%;
      display: flex;
      margin-bottom: 1rem;
      overflow: hidden;
      width: 100%;

      img {
        object-fit: cover;
        transform: scale(1);
        transition: all ease .6s;
        width: 100%;
      }
    }
  }

  .navigation .backlink-wrap {
    display: inline-block;
  }

  .backlink-wrap {
    clear: both;
    display: block;

    a {
      background: $color-primary;
      color: $white;
      display: inline-block;
      margin-top: .5rem;
      padding: .75rem 2.5rem;
    }
  }

  // Styling der Pagination
  .pagination {
    list-style-type: none;
    padding: 7px 0 5px;

    &::after {
      clear: both;
      content: '.';
      display: block;
      height: 0;
      visibility: hidden;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      float: left;
      margin-right: 15px;

      &.current {
        color: $color-primary;
        font-weight: bold;
      }
    }
  }

















  .gallery__item {
    float: left;
    margin-bottom: 6%;
    padding-right: 6%;
    overflow: hidden;
    width: 50%;

    &:nth-child(2n) {
      padding-right: 0;
    }


    &:hover {
      cursor: pointer;

      img {
        transform: scale(1.2);
      }
    }


  }



}
