.topnav-wrapper {
  background-color: rgba($color-primary, .85);
  border-bottom: 1px solid $white;
  font-family: $font-family-sans-serif;
  margin-top: 40px;
  position: relative;
  z-index: 4;
}

.topnav {
  font-size: 0;
}

.topnav__item {
  display: inline-block;
  position: relative;

  .trigger {
    color: #fff;
    display: block;
    font-size: 1.125rem;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    position: relative;
    text-decoration: none;
    text-transform: lowercase;
    transition: all 200ms linear;

    &:hover,
    &--act {
      background-color: rgba($white, .85);
      color: $color-primary;
    }
  }
}

.topnav__item--act {
  .trigger::after {
    opacity: 1;
  }

  .popup {
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }
}

.popup {
  background-color: rgba($color-secondary, .9);
  border-bottom: 4px solid $white;
  color: #fff;
  font-size: 1.125rem;
  left: 0;
  opacity: 0;
  padding: 20px 0 16px;
  position: absolute;
  top: 40px;
  transition: all 200ms linear;
  visibility: hidden;
  width: 350px;
}

.reverse-popup .popup {
  left: auto;
  right: 0;
}

.navlist--level2,
.navlist--level3,
.navlist--level4,
.navlist--level5 {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;

  a {
    color: $white;

    &:hover {
      color: $body-font-color;
    }
  }

  .item--active > a {
    color: $body-font-color;
  }
}

.navlist--level3,
.navlist--level4,
.navlist--level5 {
  list-style-type: disc;
  padding: 0 15px 0 20px;
}

.metanav {
  a {
    margin-left: 1rem;
  }
}

.navlist__link {
  display: block;
}
