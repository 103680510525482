footer {
  background-color: $color-primary;
  background-image: url('../Images/gb-footer-one.svg'), url('../Images/bg-big.svg');
  background-position: 65% 40px, left bottom;
  background-repeat: no-repeat;
  color: $white;
  font-family: $font-family-sans-serif;
  margin-top: 75px;
  padding: 3.125rem 0;

  @include breakpoint(medium only) {
    background-position: 70% 40%, -100% 0;
  }

  @include breakpoint(small only) {
    background-image: url('../Images/gb-footer-one.svg');
    background-position: 150% 150%;
    margin-top: 20px;
  }

  address {
    font-style: normal;
  }

  p {
    margin-top: 0;
  }

  a {
    display: inline-block;

    &:link,
    &:visited {
      color: $white;
    }
  }
}
