.tx-indexedsearch-searchbox {
  background: $color-secondary;
  background-image: url('../Images/gb-footer-one-white.svg');
  background-position: 180% 50%;
  background-repeat: no-repeat;
  background-size: 70%;
  margin-bottom: 20px;
  padding: .9375rem;

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .tx-indexedsearch-form {
    margin-bottom: 20px;
  }

  .tx-indexedsearch-searchbox-sword {
    border: 1px solid $color-primary;
    max-width: 100%;
    padding: 8px 10px;
    width: 100%;
  }

  .tx-indexedsearch-search-submit {
    text-align: right;
  }
}

.tx-indexedsearch-rules {
  margin-top: 20px;
}

.tx-indexedsearch-res {
  .tx-indexedsearch-percent {
    color: $color-primary;
  }

  .tx-indexedsearch-info {
    display: none;
  }

  .tx-indexedsearch-description {
    border-bottom: 1px solid $color-secondary;
    padding-bottom: 25px;
  }

  .tx-indexedsearch-redMarkup {
    color: #c50808;
  }
}

ul.tx-indexedsearch-browsebox {
  list-style-type: none;
  padding: 7px 0 5px;

  &::after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
  }

  li {
    float: left;
    margin-right: 15px;
  }
}
