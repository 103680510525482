.extension-container {
  // Listenansicht Startseite
  .news-list-view {

    h4,
    p {
      font-family: $font-family-serif;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    h4 {
      font-weight: bold;
      margin: 0 0 .125rem;
    }

    p {
      margin: 0 0 1.563rem;
    }

    a {
      p {
        color: $body-font-color;
      }
    }
  }
}

// Listenansicht Startseite
.news-list-view {

  .f3-widget-paginator {
    list-style-type: none;
    padding: 7px 0 5px;

    &::after {
      clear: both;
      content: '.';
      display: block;
      height: 0;
      visibility: hidden;
    }

    li {
      float: left;
      margin-right: 15px;

      &.current {
        color: $color-primary;
        font-weight: bold;
      }
    }
  }

  h4,
  p {
    font-family: $font-family-serif;
    font-size: 1rem;
    line-height: 1.375rem;
  }

  h4 {
    font-weight: bold;
    margin: 1rem 0 .125rem;
  }

  p {
    margin: 0 0 2rem;
  }

  a {
    p {
      color: $body-font-color;
    }
  }
}

// Detailansicht
.news-single {
  .news-list-category {
    display: block;
    margin-bottom: 1rem;
  }

  .news-img-caption {
    border-left: 4px solid $color-secondary;
    caption-side: bottom;
    color: $color-primary;
    font-size: .875rem;
    margin: 8px 0 20px 20px;
    padding-left: 5px;
  }

  .news-img-wrap {
    a {
      display: block;
      width: 100% ;
    }

    img {
      width: 85%;
    }

    video {
      background: $color-secondary;
      background-image: url('../Images/gb-footer-one-white.svg');
      background-position: 180% 50%;
      background-repeat: no-repeat;
      background-size: 70%;
      margin-bottom: 20px;
      max-height: 320px;
      width: 85%;

      @include breakpoint(small only) {
        height: 350px;
        width: 100%;
      }
    }
  }

  .news-backlink-wrap a {
    background: $color-primary;
    color: $white;
    display: inline-block;
    margin-top: .5rem;
    padding: .75rem 5rem;
  }

  .news-related-wrap {
    background: $color-secondary;
    background-image: url('../Images/gb-footer-one-white.svg');
    background-position: 180% 50%;
    background-repeat: no-repeat;
    background-size: 70%;
    color: $white;
    margin-bottom: 1.5rem;
    padding: 0 1rem;

    .news-related:first-child {
      padding-top: 1.125rem;
    }

    .news-related:last-child {
      padding-bottom: .125rem;
    }

    h4 {
      margin: 0 0 .5rem;
    }

    a {
      color: $body-font-color;

      &:hover {
        color: $white;
      }
    }

    ul {
      list-style-type: square;
      margin: .5rem 0 1.5rem;
    }
  }

  .footer {
    border-top: 2px solid $color-secondary;
    color: $color-secondary;
    margin-bottom: 2rem;
    padding-top: 1rem;
  }
}
