.filter {
  margin-bottom: 1.875rem;
}

// IE Hack
.lt-ie10 {
  .filter {
    margin-top: 3rem;
  }
}

.row--extensions {
  margin-bottom: 4rem;

  @include breakpoint(small only) {
    margin-bottom: 2.25rem;
  }
}

.extension-container {
  height: 100%;
  padding-bottom: 1.875rem;
  position: relative;

  @include breakpoint(small only) {
    margin-bottom: 6rem;
  }

  h2 {
    background-color: $color-secondary;
    color: $white;
    font-family: $font-family-sans-serif;
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 1.375rem;
    margin: 0 0 1.25rem;
    max-width: 100%;
    padding: .625rem 1.125rem .5rem 0;
    position: relative;
    text-align: right;
    text-transform: lowercase;

    &::before {
      height: 3.125rem;
      left: -.5625rem;
      position: absolute;
      top: -.5625rem;
      width: 3.125rem;

      @include breakpoint(small only) {
        top: -1rem;
      }
    }
  }

  &--news {
    h2::before {
      content: url('../Images/icon-aktuelles.svg');
    }
  }

  &--events {
    h2::before {
      content: url('../Images/icon-cal.svg');
    }
  }

  &--openinghours {

    @include breakpoint(small only) {
      border-bottom: 2px solid $color-secondary;
      margin: 0;
      padding-bottom: .5rem;
    }

    h2::before {
      content: url('../Images/icon-interface.svg');
    }
  }
}


.institution {
  background-size: 65px 65px;
  display: inline-block;
  height: 65px;
  margin: 0 -8px 50px;
  position: relative;
  transition: all 150ms ease-out;
  width: 65px;

  &:hover,
  &.active {
    transform: scale(1.2);
    z-index: 11;

    span {
      display: inline-block;
      visibility: visible;
    }
  }

  &.active {
    transform: scale(1.2);
    z-index: 11;

    span {
      display: inline-block;
      visibility: hidden;
    }
  }

  span {
    background-color: $white;
    border-left: 1px solid $color-secondary;
    color: $color-secondary;
    display: none;
    font-size: 12px;
    left: 33px;
    line-height: .875rem;
    padding: 10px 0 0 8px;
    position: absolute;
    text-align: left;
    top: 67px;
    visibility: hidden;
    width: 20rem;

    &.above {
      border-left: 0;
      left: -87px;
      padding: 0;
      text-align: center;
      top: -20px;
      visibility: hidden;
      width: 250px;
    }
  }

  &--pfarrei {
    background-image: url('../Images/signet-pfarrei.svg');
    z-index: 10;

    span {
      border-left: 1px solid $color-primary;
      color: $color-primary;
    }
  }

  &--pfarrei,
  &--andreas,
  &--antonius,
  &--heiligkreuz,
  &--joseph,
  &--lambertus,
  &--laurentius,
  &--mariamagdalena,
  &--marien,
  &--sixtus {
    &.active {
      z-index: 100;

      span.above {
        visibility: visible;
      }
    }
  }

  &--andreas {
    background-image: url('../Images/signet-andreas.svg');
    z-index: 9;
  }

  &--antonius {
    background-image: url('../Images/signet-antonius.svg');
    z-index: 8;
  }

  &--heiligkreuz {
    background-image: url('../Images/signet-heiligkreuz.svg');
    z-index: 7;
  }

  &--joseph {
    background-image: url('../Images/signet-joseph.svg');
    z-index: 6;
  }

  &--lambertus {
    background-image: url('../Images/signet-lambertus.svg');
    z-index: 5;
  }

  &--laurentius {
    background-image: url('../Images/signet-laurentius.svg');
    z-index: 4;
  }

  &--mariamagdalena {
    background-image: url('../Images/signet-mariamagdalena.svg');
    z-index: 3;
  }

  &--marien {
    background-image: url('../Images/signet-marien.svg');
    z-index: 2;
  }

  &--sixtus {
    background-image: url('../Images/signet-sixtus.svg');
    z-index: 1;
  }
}


.institution--reset {
  background-image: url('../Images/icon-onoff.svg');
  background-size: 30px 30px;
  border-radius: 50%;
  display: inline-block;
  height: 30px;
  margin: 0 3px 82px;
  position: relative;
  width: 30px;

  &.hidden {
    opacity: .2;
  }

  &.visible {
    opacity: 1;
  }

  &:hover {
    transform: scale(1.1);
    z-index: 12;

    span {
      display: inline-block;
      visibility: visible;
    }
  }

  span {
    background-color: $white;
    border-bottom: 1px solid $color-primary;
    color: $color-primary;
    display: none;
    font-size: 12px;
    left: 35px;
    line-height: .875rem;
    padding: 0 0 2px 5px;
    position: absolute;
    text-align: right;
    top: 0;
    visibility: hidden;
    width: 8rem;
  }
}


[data-church],
.openinghours__background {
  background-position: 11px 0;
  background-repeat: no-repeat;
  background-size: 12%;
  transition: opacity 250ms linear;
  min-height: 116px;

  &.hidden {
    opacity: .2;
  }

  &.visible {
    opacity: 1;
  }
}

[data-church='1'] {
  background-image: url('../Images/signet-pfarrei.svg');
}

[data-church='2'] {
  background-image: url('../Images/signet-andreas.svg');
}

[data-church='3'] {
  background-image: url('../Images/signet-antonius.svg');
}

[data-church='4'] {
  background-image: url('../Images/signet-heiligkreuz.svg');
}

[data-church='5'] {
  background-image: url('../Images/signet-joseph.svg');
}

[data-church='6'] {
  background-image: url('../Images/signet-lambertus.svg');
}

[data-church='7'] {
  background-image: url('../Images/signet-laurentius.svg');
}

[data-church='8'] {
  background-image: url('../Images/signet-mariamagdalena.svg');
}

[data-church='9'] {
  background-image: url('../Images/signet-marien.svg');
}

[data-church='10'] {
  background-image: url('../Images/signet-sixtus.svg');
}

.openinghours__background {
  background-image: url('../Images/clock.svg');
}
