.infobox {
  align-items: center;
  border: 1px solid rgba(#3b38a3, .2);
  display: flex;
  flex-flow: row nowrap;
  height: 180px;
  justify-content: center;
  margin-bottom: 1.563rem;
  overflow: hidden;
  position: relative;
  text-align: center;

  &::after {
    background-color: rgba(#d2e3fb, .9);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 350ms ease-out;
    width: 100%;
    z-index: 3;
  }

  img {
    display: block;
    filter: grayscale(100%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 350ms ease-in;
    width: 100%;
  }

  p {
    color: $color-primary;
    font-family: $font-family-sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: 0;
    max-width: 90%;
    transition: all 350ms ease-out;
    z-index: 4;
  }
}

.infobox:hover {

  &::after {
    background-color: rgba(#d2e3fb, 0);
  }

  img {
    filter: grayscale(0%);
    transform: scale(1.1);
  }

  p {
    color: $white;
    text-shadow: 0 0 3px #000;
    transform: scale(1.05);
  }
}
