header {
  padding-top: 40px;

  @include breakpoint(small only) {
    padding-top: 70px;
  }
}

.quote {
  font-family: $font-family-serif;
  font-size: .75rem;
  margin-top: .875rem;
  text-align: right;
}

.logo {
  margin-top: 5px;

  @include breakpoint(small only) {
    margin: .625rem;
  }
}


.logo-signet {
  transition: all 200ms ease-in;

  @include breakpoint(small only) {
    margin-bottom: 5px;
  }

  &:hover {
    transform: scale(1.25);
  }
}

.logo-signet--mobile {
  height: 35px;
  position: fixed;
  right: .625rem;
  top: .625rem;
  width: 35px;
}
