@keyframes wave-away {
  100% {
    bottom: -12px;
    left: -12px;
    opacity: 0;
    right: -12px;
    top: -12px;
  }
}

.btn-wave-away {
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  background: $color-primary;
  border: 0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  color: $white !important;
  display: inline-block;
  margin-top: .5rem;
  padding: .75rem 5rem;
  position: relative;
  transform: translateZ(0);
  vertical-align: middle;
}

.btn-wave-away::before {
  animation-duration: 1s;
  border: $color-primary solid 6px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.btn-wave-away:hover:before,
.btn-wave-away:focus:before,
.btn-wave-away:active:before {
  animation-name: wave-away;
}

